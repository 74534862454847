<template>
  <div>
    <AppHeaderDropdown right no-caret>
      <template slot="header">
        <template v-if="user && d_showProfilePicture">
          <img :src="profile_picture" :key="'profile_picture_' + d_showProfilePicture ? 'yes' : 'no'" class="img-avatar" width="45" height="45" />
        </template>
        <template v-else>
          <img src="@/icon/2940373.png" style="width: 2em;" />
        </template>
      </template>
      <template slot="dropdown">
        <b-dropdown-header v-if="user" tag="div" class="text-center">
          <strong>{{ user.first_name.toLocaleUpperCase('tr') + ' ' + user.last_name.toLocaleUpperCase('tr') }} </strong>
        </b-dropdown-header>
        <b-dropdown-header v-if="user" tag="div" class="text-center">
          <strong>Departmanlar</strong>
        </b-dropdown-header>
        <template v-if="user">
          <b-dropdown-item v-for="(pdt, pdt_ind) in user.position_department_title" :key="'user.position_department_title_'+ pdt_ind">
            <span v-if="pdt.active && pdt.active === 1" class="text-success">
              {{ pdt.title.label }}. {{ pdt.department.label }} ({{ pdt.position.label }})
            </span>
            <span v-else @click="fGoToThisDepartment(pdt_ind)">
              {{ pdt.title.label }}. {{ pdt.department.label }} ({{ pdt.position.label }})
            </span>
          </b-dropdown-item>
        </template>
        <b-dropdown-header v-if="user" tag="div" class="text-center">
          <strong>İşlemler</strong>
        </b-dropdown-header>
        <!-- <b-dropdown-item v-if="user" @click="d_showVersions = true"><i class="fa fa-bell-o" /> Güncellemeler
          <b-badge variant="info">&nbsp</b-badge>
        </b-dropdown-item> -->
        <!--         <b-dropdown-item><i class="fa fa-envelope-o" /> Mesajlar
          <b-badge variant="success">&nbsp</b-badge>
        </b-dropdown-item>
 -->
        <!--         <b-dropdown-item><i class="fa fa-tasks" /> Görevlerim
          <b-badge variant="danger">&nbsp</b-badge>
        </b-dropdown-item>
 -->
        <!--         <b-dropdown-item><i class="fa fa-comments" /> Yorumlar
          <b-badge variant="warning">&nbsp</b-badge>
        </b-dropdown-item>
 -->
        <!-- <b-dropdown-header v-if="user" tag="div" class="text-center">
          <strong>Ayarlar</strong>
        </b-dropdown-header> -->
        <!-- <b-dropdown-item><i class="fa fa-user" /> Profil</b-dropdown-item> -->
        <!-- <b-dropdown-item @click="$router.push({ path: '/web/dashboard', query: {} })"><i class="fa fa-wrench" /> Tanıtım Anasayfa </b-dropdown-item> -->
        <!-- <b-dropdown-item v-if="user" @click="$router.push({ path: '/settings/user', query: {} })"><i class="fa fa-wrench" /> Ayarlar</b-dropdown-item> -->
        <b-dropdown-item v-if="!user && d_wisdomEraSystem && d_wisdomEraSystem.system_type !== 'customer'" @click="$router.push({ path: '/register', query: {} })"><i class="fa fa-lock" /> kayıt ol</b-dropdown-item>
        <b-dropdown-item v-if="!user" @click="$router.push({ path: '/login', query: {} })"><i class="fa fa-openid" /> giriş</b-dropdown-item>
        <b-dropdown-item @click="f_showLanguageChange()">
          <i class="fa fa-file" /> Aktif Dil ( {{ d_languageChange.active_lang }} )
        </b-dropdown-item>
        <!-- <b-dropdown-item v-if="user && (user.username === 'wisdom.support' || user.username === 'wisdom')" @click="f_goToMemberShip()">
          <i class="fa fa-file" /> Üyelik tipi
        </b-dropdown-item> -->
        <b-dropdown-divider v-if="user" />
        <b-dropdown-item v-if="user" @click="$router.push({ path: '/logout', query: {} })"><i class="fa fa-lock" /> Çıkış yap</b-dropdown-item>
      </template>
    </AppHeaderDropdown>
    <modal v-if="d_showVersions" @close="d_showVersions = false" large="True">
      <h3 slot="header"> WisdomEra Versiyon Güncellemeleri </h3>
      <div slot="body">
        <version></version>
      </div>
      <div slot="footer">
        <button type="button" class="btn btn-danger" @click="d_showVersions = false">{{ $t('wdm16.4053') }}</button>
      </div>
    </modal>
    <b-modal v-if="d_languageChange.show" :v-model="d_languageChange.show" size="lg" title="$t('wdm16.13247')" centered hide-footer body-bg-variant="secondary" header-bg-variant="secondary">
      <b-row>
        <b-col cols="4">
          Aktif Dil
        </b-col>
        <b-col cols="8">
          <b-form-select v-model="d_languageChange.active_lang">
            <option v-for="(lang, lang_ind) in d_languageChange.language_list" :value="lang.value">
              {{ lang.label }}
            </option>
          </b-form-select>
        </b-col>
      </b-row>
      <b-row style="min-height: 50px; margin-top: 20px;">
        <b-col cols="12">
          <b-button variant="success" class="pull-right" v-if="d_languageChange.old_lang !== d_languageChange.active_lang" @click="f_saveLanguageChange()">{{ $t('wdm16.4061') }}</b-button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import {
  default as Version
} from '@/components/widgets/Version';
import {
  default as Modal
} from '@/components/widgets/Modal';
import auth from '@/auth';
import UserService from '@/services/users';
import { mapGetters, mapActions } from 'vuex';
import { HeaderDropdown as AppHeaderDropdown } from '@coreui/vue'
export default {
  name: 'DefaultHeaderDropdownAccnt',
  computed: mapGetters({
    StoreUser: 'user'
  }),
  components: {
    AppHeaderDropdown,
    Modal,
    Version
  },
  data: () => {
    return {
      d_languageChange: {
        'show': false,
        'language_list': [],
        'active_lang': '',
        'old_lang': ''
      },
      user: {},
      profile_picture: '',
      d_showVersions: false,
      d_showProfilePicture: true,
      d_wisdomEraSystem: false,
      d_activeLanguage: ''
    }
  },
  created: function () {
    if (localStorage.getItem('wisdomera_system')) {
      this.d_wisdomEraSystem = JSON.parse(localStorage.getItem('wisdomera_system'));
    }
    this.user = JSON.parse(localStorage.getItem('user'));
    if (localStorage.getItem('active_lang')) {
      this.d_activeLanguage = localStorage.getItem('active_lang');
    }
    if (this.user) {
      this.d_languageChange.active_lang = this.user.active_lang;
      this.d_activeLanguage = this.user.active_lang;
    } else {
      if (this.d_activeLanguage) {
        this.d_languageChange.active_lang = this.d_activeLanguage;
      } else {
        this.d_languageChange.active_lang = 'en';
      }
    }
    let prof_pic = localStorage.getItem('profile_picture');
    if (!prof_pic || prof_pic === undefined || prof_pic === 'undefined' || prof_pic === '') {
      this.profile_picture = 'img/doctor_man.png';
    } else {
      this.profile_picture = JSON.parse(prof_pic);
    }
  },
  mounted: function () {
    if (!this.profile_picture || this.profile_picture === undefined || this.profile_picture === 'undefined' || this.profile_picture === '') {
      this.profile_picture = 'img/doctor_man.png';
    }
    /*
    let position_department_title = [];
    if (this.user.position_department_title) {
      for (let k in this.user.position_department_title) {
        let x = {
          'active': this.user.position_department_title[k].active,
          'department': this.user.position_department_title[k].department,
          'position': this.user.position_department_title[k].position,
          'title': this.user.position_department_title[k].title
        }
        position_department_title.push(x);
      }
    }
    this.$store.commit('user', { 'username': this.user.username, 'first_name': this.user.first_name, 'last_name': this.user.last_name, 'position_department_title': position_department_title });
    */
  },
  methods: {
    f_goToMemberShip: function () {
      this.$router.push({ path: '/membership', query: {} });
    },
    f_showLanguageChange: function () {
      this.d_languageChange.active_lang = this.d_activeLanguage;
      this.d_languageChange.old_lang = this.d_activeLanguage;
      this.d_languageChange.show = true;
      this.d_languageChange.language_list = []
      if (this.d_wisdomEraSystem) {
        this.d_languageChange.language_list = this.d_wisdomEraSystem.languages;
      }
    },
    f_saveLanguageChange: function () {
      if (this.user) {
        this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Aktif diliniz değiştiriliyor. Lütfen bekleyiniz' } });
        UserService.save_language_change({ 'active_lang': this.d_languageChange.active_lang })
          .then(resp => {
            if (resp.data.status === 'success') {
              this.$store.commit('Loading', { 'status': false, 'data': {} });
              this.user.active_lang = this.d_languageChange.active_lang;
              localStorage.setItem('user', JSON.stringify(this.user));
              auth.f_getWdm16IdMeaningWithLangId(this.$i18n, 'this', this.d_languageChange.active_lang);
              this.$store.commit('lang', this.d_languageChange.active_lang);
              this.d_languageChange.show = false;
              // alert('Dil değiştirildi');
            } else {
              alert('error' + resp.data.message);
            }
          });
      } else {
        auth.f_getWdm16IdMeaningWithLangId(this.$i18n, 'this', this.d_languageChange.active_lang);
        this.d_languageChange.show = false;
        // alert('Dil değiştirildi');
      }
    },
    fGoToThisDepartment: function (pdt_ind) {
      UserService.changeActiveUserDepartment({ 'new_active_department': this.user.position_department_title[pdt_ind].department.value, 'username': this.user.username })
        .then(resp => {
          this.$store.commit('user', { 'username': '', 'first_name': '', 'last_name': '', 'position_department_title': [] });
          let credentials = {
            username: this.user.username,
            password: 'password',
            multi_account_password: 0,
            not_control_password: 1
          };
          auth.login(this, credentials, '/');
        });
    }
  },
  watch: {
    'StoreUser': function () {
      this.user = JSON.parse(localStorage.getItem('user'));
      this.d_showProfilePicture = false;
      this.profile_picture = 'img/doctor_man.png';
      setTimeout(function () {
        this.profile_picture = JSON.parse(localStorage.getItem('profile_picture'));
        if (!this.profile_picture || this.profile_picture === undefined || this.profile_picture === '') {
          this.profile_picture = 'img/doctor_man.png';
        }
      }.bind(this), 2000)
      this.d_showProfilePicture = true;
      this.$forceUpdate();
    }
  }
}

</script>

