import moment from "moment";
class DateFunctions {
  static day_dif = function(date_1, date_2) { // 1 eski 2 yeni tarih.Daha sonra ikiden biri çıkarıyoruz.
    if (date_2 === 'today') {
      date_2 = new Date();
      date_2.setHours(0, 0, 0, 0);
    } else {
      date_2 = new Date(date_2);
    }
    date_1 = new Date(date_1);
    let date_1_Msec = date_1.getTime();
    let date_2_Msec = date_2.getTime();
    let msecPerDay = 86400000;
    return Math.round((date_2_Msec / msecPerDay) - (date_1_Msec / msecPerDay));
  };

  static datetime_dif = function(date_1, date_2) { // 1 eski 2 yeni tarih.Daha sonra ikiden biri çıkarıyoruz.
    date_1 = new Date(date_1);
    date_2 = new Date(date_2);
    // let date_1_Msec = date_1.getTime();
    // let date_2_Msec = date_2.getTime();
    // Mert bana gitbash öğretiyor
    return date_2 - date_1;
  };

  // static DateTimeFormat = function(date) {};

  static formatDate = function(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  };

  static date_dif_today = function(dt) {
    let date = new Date(dt);
    let dateMsec = date.getTime();
    let today = new Date();
    today.setHours(8, 0, 0, 0);
    let todayMsec = today.getTime();
    let msecPerDay = 86400000;
    let day_dif = Math.round((dateMsec / msecPerDay) - (todayMsec / msecPerDay));
    return day_dif;
  };

  static DateFormat = function(date, lang = 'tr') {
    if (date) {
      let months = {
        '01': { 'translation': { 'en': 'january', 'tr': 'ocak' } },
        '02': { 'translation': { 'en': 'february', 'tr': 'şubat' } },
        '03': { 'translation': { 'en': 'march', 'tr': 'mart' } },
        '04': { 'translation': { 'en': 'april', 'tr': 'nisan' } },
        '05': { 'translation': { 'en': 'may', 'tr': 'mayıs' } },
        '06': { 'translation': { 'en': 'june', 'tr': 'haziran' } },
        '07': { 'translation': { 'en': 'july', 'tr': 'temmuz' } },
        '08': { 'translation': { 'en': 'august', 'tr': 'ağustos' } },
        '09': { 'translation': { 'en': 'september', 'tr': 'eylül' } },
        '10': { 'translation': { 'en': 'october', 'tr': 'ekim' } },
        '11': { 'translation': { 'en': 'november', 'tr': 'kasım' } },
        '12': { 'translation': { 'en': 'december', 'tr': 'aralık' } }
      };
      let x = moment(date, 'YYYY-MM-DD hh:mm:ss').format('DD/MM/YYYY');
      let y = x.split('/');
      return y[0] + ' ' + months[y[1]]['translation'][lang] + ' ' + y[2];
    } else {
      return '';
    }
  };
};

export { DateFunctions };
