<template>
  <div>
    <b-form-input v-model="filterTable"></b-form-input>
    <b-pagination class="pull-right" aria-controls="d_versionList" v-model="d_versionListTablePageData['current']" :total-rows="d_versionList.length" :per-page="d_versionListTablePageData['per-page']"></b-pagination>
    <b-table id="d_versionList" :per-page="d_versionListTablePageData['per-page']" :current-page="d_versionListTablePageData['current']" thead-tr-class="/*search-patient-table-thead-class*/" head-variant="dark" striped :filter="filterTable" responsive stacked="sm" ref="ref_doctorProtocolList" bordered small hover :items="d_versionList" :fields="versionColumns" selectable select-mode="single" selectedVariant="success" @row-selected="" @row-contextmenu="" @row-clicked="" @row-dblclicked="" @row-hovered="" @row-unhovered="">
      <!--       <template v-slot:cell(actions)="row">
        <b-dropdown variant="success" size="sm">
          <b-dropdown-item @click=""></b-dropdown-item>
        </b-dropdown>
      </template>
 -->
      <template v-slot:cell(rowNumber)="row">
        {{ ((d_versionListTablePageData['current'] - 1) * d_versionListTablePageData['per-page']) + row.index + 1 }}
      </template>
      <template v-slot:cell(message)="row">
        <b-row> <b-col cols="12">({{ row.item.message.type }}) -> {{ row.item.message.message }}</b-col> </b-row>
      </template>
      <template v-slot:cell(date)="row">
        {{ moment(row.item.date).format("DD/MM/YYYY HH:mm") }}
      </template>
    </b-table>
  </div>
</template>

<script>
import VersionService from '@/services/version';
import moment from 'moment';
import { mapGetters } from 'vuex';
export default {
  name: 'Version',
  computed: mapGetters({
    lang: 'lang'
  }),
  components: {},
  created: function () {
    VersionService.get_all_versions()
      .then(resp => {
        if (resp.data.status === 'success') {
          this.d_versionList = resp.data.result;
        } else {
          alert('version error: ', resp.data.message);
        }
      });
  },
  mounted: function () {},
  data () {
    return {
      filterTable: '',
      d_versionList: [],
      moment: moment,
      d_versionListTablePageData: { 'current': 1, 'per-page': 50 },
      versionColumns: [{
        key: "rowNumber",
        label: " "
      }, {
        key: "date",
        label: "Tarih"
      }, {
        key: "version",
        label: "Versiyon"
      }, {
        key: "message",
        label: "Mesajlar"
      }, {
        key: "wdm_update",
        label: "Wisdom Data Model Güncelleme"
      }, {
        key: "wdm",
        label: "Güncellenen wisdom Data Modelleri"
      }, {
        key: "this_department",
        label: "Mesaj Departmanı"
      }, {
        key: "selected_user",
        label: "Mesaj Kullanıcıları"
      }]
    };
  },
  methods: {}
};

</script>

<style type="text/css">


</style>

