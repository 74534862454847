<template>
  <b-tabs>
    <b-tab v-if="selected_mes_ind === ''">
      <template slot="title">
        <i class='icon-list'></i>
      </template>
      <b-form-input v-model="user_search" placeholder="arama...."></b-form-input>
      <b-list-group class="list-group-accent">
        <template v-for="(mes, mes_ind) in messenger_data">
          <template v-if="mes.user && f_searchUser(mes)">
            <b-list-group-item class="list-group-item-accent-info" @click="f_selectThisUser(mes_ind)" style="cursor: pointer;">
              <div class="avatar float-right">
                <!-- <img src="img/avatars/7.jpg" class="img-avatar" alt="admin@bootstrapmaster.com"> -->
                <!-- <img class="img-avatar" src="img/avatars/4.jpg" alt="admin@bootstrapmaster.com"> -->
              </div>
              <div><strong>{{ mes.user.first_name + ' ' + mes.user.last_name }}</strong> </div>
              <!-- <small class="text-muted mr-3"><i class="icon-calendar"></i>&nbsp;&nbsp;4 - 5pm</small> -->
              <small class="text-muted"><!-- <i class="icon-social-skype"> --></i>&nbsp;&nbsp;On-line</small>
              <b-badge pill variant="warning" v-if="f_hasNotSeenMessage(mes)"> yeni mesaj </b-badge>
            </b-list-group-item>
            <hr class="transparent mx-3 my-0">
          </template>
        </template>
        <!--         <b-list-group-item class="list-group-item-accent-secondary bg-light text-center font-weight-bold text-muted text-uppercase small">
          Today
        </b-list-group-item> -->
        <!--         <b-list-group-item href="#" class="list-group-item-accent-warning list-group-item-divider">
          <div class="avatar float-right">
            <img class="img-avatar" src="img/avatars/7.jpg" alt="admin@bootstrapmaster.com">
          </div>
          <div>Meeting with
            <strong>Lucas</strong>
          </div>
          <small class="text-muted mr-3">
            <i class="icon-calendar"></i>&nbsp;&nbsp;1 - 3pm
          </small>
          <small class="text-muted">
            <i class="icon-location-pin"></i>&nbsp;&nbsp;Palo Alto, CA
          </small>
        </b-list-group-item> -->
        <!--         <b-list-group-item class="list-group-item-accent-secondary bg-light text-center font-weight-bold text-muted text-uppercase small">
          Tomorrow
        </b-list-group-item> -->
        <!--         <b-list-group-item href="#" class="list-group-item-accent-danger list-group-item-divider">
          <div>New UI Project - <strong>deadline</strong>
          </div>
          <small class="text-muted mr-3"><i class="icon-calendar"></i>&nbsp;&nbsp;10 - 11pm</small>
          <small class="text-muted"><i class="icon-home"></i>&nbsp;&nbsp;creativeLabs HQ</small>
          <div class="avatars-stack mt-2">
            <div class="avatar avatar-xs">
              <img class="img-avatar" src="img/avatars/2.jpg" alt="admin@bootstrapmaster.com">
            </div>
            <div class="avatar avatar-xs">
              <img class="img-avatar" src="img/avatars/3.jpg" alt="admin@bootstrapmaster.com">
            </div>
            <div class="avatar avatar-xs">
              <img class="img-avatar" src="img/avatars/4.jpg" alt="admin@bootstrapmaster.com">
            </div>
            <div class="avatar avatar-xs">
              <img class="img-avatar" src="img/avatars/5.jpg" alt="admin@bootstrapmaster.com">
            </div>
            <div class="avatar avatar-xs">
              <img class="img-avatar" src="img/avatars/6.jpg" alt="admin@bootstrapmaster.com">
            </div>
          </div>
        </b-list-group-item>
        <b-list-group-item href="#" class="list-group-item-accent-success list-group-item-divider">
          <div><strong>#10 Startups.Garden</strong> Meetup</div>
          <small class="text-muted mr-3"><i class="icon-calendar"></i>&nbsp; 1 - 3pm</small>
          <small class="text-muted"><i class="icon-location-pin"></i>&nbsp; Palo Alto, CA</small>
        </b-list-group-item>
        <b-list-group-item href="#" class="list-group-item-accent-primary list-group-item-divider">
          <div><strong>Team meeting</strong></div>
          <small class="text-muted mr-3"><i class="icon-calendar"></i>&nbsp; 4 - 6pm</small>
          <small class="text-muted"><i class="icon-home"></i>&nbsp; creativeLabs HQ</small>
          <div class="avatars-stack mt-2">
            <div class="avatar avatar-xs">
              <img src="img/avatars/2.jpg" class="img-avatar" alt="admin@bootstrapmaster.com">
            </div>
            <div class="avatar avatar-xs">
              <img src="img/avatars/3.jpg" class="img-avatar" alt="admin@bootstrapmaster.com">
            </div>
            <div class="avatar avatar-xs">
              <img src="img/avatars/4.jpg" class="img-avatar" alt="admin@bootstrapmaster.com">
            </div>
            <div class="avatar avatar-xs">
              <img src="img/avatars/5.jpg" class="img-avatar" alt="admin@bootstrapmaster.com">
            </div>
            <div class="avatar avatar-xs">
              <img src="img/avatars/6.jpg" class="img-avatar" alt="admin@bootstrapmaster.com">
            </div>
            <div class="avatar avatar-xs">
              <img src="img/avatars/7.jpg" class="img-avatar" alt="admin@bootstrapmaster.com">
            </div>
            <div class="avatar avatar-xs">
              <img src="img/avatars/8.jpg" class="img-avatar" alt="admin@bootstrapmaster.com">
            </div>
          </div>
        </b-list-group-item> -->
      </b-list-group>
    </b-tab>
    <b-tab v-if="selected_mes_ind !== ''" @click="selected_mes_ind = ''; SendingText = ''">
      <template slot="title">
        <i class='icon-speech'></i>
      </template>
      <!-- <div class="p-3"> <div class="message"> Dikkat! Mesajlaşma kayıt edilmemektedir. Anlık mesajlaşma yapılabilmektedir. </div> <hr> </div> -->
      <b-card header-tag="header" footer-tag="footer" no-body key="nav-give_permission" style="height:100%;">
        <b-card-header header-bg-variant="success" header-text-variant="dark" class="p-1">
          <b> {{ messenger_data[selected_mes_ind].user.first_name + ' ' + messenger_data[selected_mes_ind].user.last_name }} </b>
        </b-card-header>
        <div style="border: solid 1px #adffdc; border-radius: 5px; padding: 5px; background-color: #e4f8f3;">
          <b-row v-for="(message, message_ind) in messenger_data[selected_mes_ind].message" :key="'messenger_data_selected_mes_ind_.message_' + message_ind" style="margin: 0px; padding: 5px;">
            <b-col cols="6" style="padding-left: 0px; ">
              <span v-if="message.sender !== user.username">
                <b-badge class="float-left" variant="primary" style="padding: 5px; color: black; font-size: 12px;" size="md">{{ message.message }}</b-badge>
              </span>
            </b-col>
            <b-col cols="6" style="padding-right: 0px; ">
              <span v-if="message.sender === user.username">
                <b-badge class="float-right" variant="success" style="padding: 5px; color: black; font-size: 12px;" size="md">{{ message.message }}</b-badge>
              </span>
            </b-col>
          </b-row>
        </div>
        <div>
          <div class="message">
            <div>
              <b-form-textarea id="textarea" v-model="SendingText" placeholder="Mesajınızı yazınız..." rows="3" max-rows="6"></b-form-textarea>
            </div>
          </div>
          <div class="message">
            <div class="float-right">
              <b-button v-if="SendingText" @click="f_sendMessage()" variant="success"> <i class="fa fa-send"></i></b-button>
            </div>
          </div>
        </div>
      </b-card>
    </b-tab>
    <b-tab v-if="false">
      <template slot="title">
        <i class='icon-settings'></i>
      </template>
      <div class="p-3">
        <h6>Settings</h6>
        <div class="aside-options">
          <div class="clearfix mt-4">
            <small><b>Option 1</b></small>
            <c-switch color="success" label variant="pill" size="sm" class="float-right" checked />
          </div>
          <div>
            <small class="text-muted">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</small>
          </div>
        </div>
        <div class="aside-options">
          <div class="clearfix mt-3">
            <small><b>Option 2</b></small>
            <c-switch color="success" label variant="pill" size="sm" class="float-right" />
          </div>
          <div>
            <small class="text-muted">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</small>
          </div>
        </div>
        <div class="aside-options">
          <div class="clearfix mt-3">
            <small><b>Option 3</b></small>
            <c-switch color="success" label variant="pill" size="sm" class="float-right" disabled defaultChecked/>
          </div>
          <div>
            <small class="text-muted">Disabled option.</small>
          </div>
        </div>
        <div class="aside-options">
          <div class="clearfix mt-3">
            <small><b>Option 4</b></small>
            <c-switch color="success" label variant="pill" size="sm" class="float-right" checked />
          </div>
        </div>
        <hr>
        <h6>System Utilization</h6>
        <div class="text-uppercase mb-1 mt-4">
          <small><b>CPU Usage</b></small>
        </div>
        <b-progress height={} class="progress-xs" variant="info" :value="25"></b-progress>
        <small class="text-muted">348 Processes. 1/4 Cores.</small>
        <div class="text-uppercase mb-1 mt-2">
          <small><b>Memory Usage</b></small>
        </div>
        <b-progress height={} class="progress-xs" variant="warning" :value="70"></b-progress>
        <small class="text-muted">11444GB/16384MB</small>
        <div class="text-uppercase mb-1 mt-2">
          <small><b>SSD 1 Usage</b></small>
        </div>
        <b-progress height={} class="progress-xs" variant="danger" :value="95"></b-progress>
        <small class="text-muted">243GB/256GB</small>
        <div class="text-uppercase mb-1 mt-2">
          <small><b>SSD 2 Usage</b></small>
        </div>
        <b-progress height={} class="progress-xs" variant="success" :value="10"></b-progress>
        <small class="text-muted">25GB/256GB</small>
      </div>
    </b-tab>
  </b-tabs>
</template>

<script>
import MessengerService from '@/services/messenger';
import moment from 'moment';
import { Switch as cSwitch } from '@coreui/vue'
import { mapGetters } from 'vuex';
export default {
  name: 'DefaultAside',
  computed: {
    ...mapGetters({
      messenger_data: 'messenger_data',
    })
  },
  data () {
    return {
      user_search: '',
      SendingText: '',
      selected_mes_ind: '',
      recorded_messages: {},
      selected_user_ind: 'none',
      user: {},
      SocketList: {}
    }
  },
  mounted: function () {},
  created: function () {
    this.user = JSON.parse(localStorage.getItem('user'));
    this.getSocketData();
  },
  methods: {
    f_hasNotSeenMessage: function (mes) {
      for (let i in mes['message']) {
        if (mes['message'][i]['status'] === 'wait') {
          return true;
        }
      }
      return false;
    },
    f_selectThisUser: function (mes_ind) {
      this.selected_mes_ind = mes_ind;
      let data = { 'user': this.messenger_data[mes_ind]['user']['username'] };
      MessengerService.see_this_user_messages(data)
        .then(resp => {
          if (resp.data.status === 'success') {
            let x = JSON.parse(JSON.stringify(this.messenger_data));
            for (let i in x[mes_ind].message) {
              x[mes_ind].message[i].status = 'seen';
            }
            this.$store.commit('messenger_data', x);
          } else {
            console.log('error', resp.data.message);
          }
        })
    },
    f_searchUser: function (mes) {
      if (this.user_search) {
        let x = mes.user.first_name + mes.user.last_name;
        if (x.toLocaleLowerCase('tr').indexOf(this.user_search.toLocaleLowerCase('tr')) !== -1) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    DateFormatHour: function (date) {
      return moment(date).format('hh:mm');
    },
    getSocketData: function () {
      this.$socket.send(JSON.stringify({ 'type': 'getSocketData' }));
    },
    f_sendMessage: function () {
      let data = { 'message': this.SendingText, 'receiver': this.messenger_data[this.selected_mes_ind].user.username };
      MessengerService.send_message_to_this_user(data)
        .then(resp => {
          if (resp.data.status === 'success') {
            let x = JSON.parse(JSON.stringify(this.messenger_data));
            x[this.selected_mes_ind].message.push(resp.data.result);
            this.$store.commit('messenger_data', x);

          } else {
            console.log('error', resp.data.message);
          }
        })
    },
    SendUserMessage: function () {
      if (!this.recorded_messages[this.all_users[this.selected_user_ind].username]) {
        this.recorded_messages[this.all_users[this.selected_user_ind].username] = [];
      }
      let dt = new Date().toISOString();
      this.recorded_messages[this.all_users[this.selected_user_ind].username].push({ 'send_by': this.user.username, 'get_by': this.all_users[this.selected_user_ind].username, 'message': this.SendingText, 'date': dt });
      this.$socket.send(JSON.stringify({ 'type': 'UserMessage', 'data': this.recorded_messages[this.all_users[this.selected_user_ind].username], 'send_by': this.user.username, 'get_by': this.all_users[this.selected_user_ind].username }));
      this.$forceUpdate();
    },
  },
  components: {
    cSwitch
  },
}

</script>

